<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1 card-sipymex">
        <v-row>
          <v-col cols="12" md="9">
            <span class="white--text">Mis Contratos</span>
          </v-col>
          <v-col cols="12" md="3">
            <span class="m-20 float-right">
              <v-btn small color="white" :to="{ name: 'create-contract' }">
                <v-icon small dark> mdi-plus-circle </v-icon> Crear contrato
              </v-btn>
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
        <br />

        <v-data-table
          :headers="headers"
          :items="contracts"
          :search="search"
          :footer-props="{
            itemsPerPageText: 'Filas por página',
            itemsPerPageAllText: 'todos',
          }"
          no-data-text="No hay datos disponibles"
          no-results-text="No hay datos disponibles"
        >
          <template v-slot:item.name_plan="{ item }">
            <span>{{ getNamePlan(item.id_plan) }}</span>
          </template>

          <template v-slot:item.name_customer="{ item }">
            <span>{{ getNameCustomer(item.id_customer) }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon :to="'/update-contract/' + item.id">
                  <v-icon v-bind="attrs" color="primary" v-on="on">
                    mdi-pencil-box-multiple
                  </v-icon>
                </v-btn>
              </template>
              <span>Modificar</span>
            </v-tooltip>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon v-bind="attrs" color="red darken-2" v-on="on">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Mensaje</v-toolbar>
                  <v-card-text>
                    <div class="text-h7 pa-6">
                      ¿Estás seguro que deseas eliminar el contrato?
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cancelar</v-btn>
                    <v-btn
                      class="primary"
                      text
                      @click="
                        dialog.value = false;
                        deleteItem(item);
                      "
                      >Aceptar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    dialog: false,
    search: "",
    headers: [
      { text: "Nombre de Empresa", value: "name_customer" },
      { text: "Plan", value: "name_plan" },
      { text: "Fecha de Contratación", value: "date" },
      { text: "Fecha de Facturación", value: "date_next_billing" },
      { text: "Acciones", value: "actions" },
    ],
    contracts: [],
    customers: [],
    plans: [],
  }),

  computed: {},
  mounted() {
    this.action = "Eliminar";
    this.getContracts();
    this.getCustomers();
    this.getPlans();
  },
  watch: {},

  methods: {
    getNameService(id_service) {
      //  this.getServices();
      let serviceName = this.services.find(
        (service) => service.id === id_service
      );

      return serviceName.name;
    },
    getNamePlan(id_plan) {
      //  this.getServices();
      let plan = this.plans.find((plan) => plan.id === id_plan);

      return plan.name;
    },

    getNameCustomer(id_customer) {
      //  this.getServices();
      let customer = this.customers.find(
        (customer) => customer.id === id_customer
      );

      return customer.legal_name;
    },

    deleteItem(item) {
      console.log("eliminando");
      console.log(item);
      let request = {
        id: item.id,
      };
      axios
        .post("/destroy-contract", request)
        .then((response) => {
          this.getContracts();
          this.getCustomers();
          this.getPlans();
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "No se pudo eliminar el contrato"
          );
        });
    },

    getServices() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Customers");
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener servicios"
          );
        });
    },

    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },

    getContracts() {
      let request = {
        id_customer: this.$route.params.id,
      };
      axios
        .post("/get-contracts-by-customer", request)
        .then((response) => {
          console.log(response.data);
          this.contracts = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener contratos"
          );
        });
    },
    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log("Clientes");
          console.log(response.data);
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes"
          );
        });
    },
  },
};
</script>